// CSS Imports
import '/src/css/style.pcss';

// NPM Imports
import 'lazysizes';
import 'alpinejs';

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


const galleryThumbs = new Swiper(".galleryThumbs", {
  slidesPerView: "auto",
  freeMode: true,
  watchSlidesProgress: true,
  autoScrollOffset: 3,
});

const gallery = new Swiper(".gallery", {
  // effect: "fade",
  grabCursor: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

  thumbs: {
    swiper: galleryThumbs,
    slideThumbActiveClass: 'sm:opacity-100 sm:ring-2 sm:ring-offset-1 sm:ring-blue-500'
  },
});

//COMPONENTS IMPORTS
// import swiperProjects from './components/swiper-projects.example';
// import gallery from './components/gallery.example';
//
// //INIT COMPONENTS
// swiperProjects();
// gallery();
